import { Form, Button, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';

init("user_IkE5mJqkZSDB8PviljJcD");

const StyledButton = styled(Button)`
  border-radius: 5px;
`;

const StyledDiv = styled.div`
  text-align:left;
`;


const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const validationSchema = Yup.object({
  firstName: Yup.string()
    .min(2, "*Names must have at least 2 characters")
    .max(100, "*Names can't be longer than 100 characters")
    .required("*First Name is required"),
  lastName: Yup.string()
    .min(2, "*Names must have at least 2 characters")
    .max(100, "*Names can't be longer than 100 characters")
    .required("*Last Name is required"),
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  phone: Yup.string()
    .matches(phoneRegExp, "*Phone number is not valid"),
    // .required("*Phone number required"),
  message: Yup.string()
    .min(2, "*Message must have at least 2 characters")
    .max(300, "*Message can't be longer than 100 characters")
    .required("*Message is required"),
});

function sendEmail(values) {
  emailjs.send('service_kpwubfo', 'template_fr2f40h', values)
    .then(function (response) {
      console.log('SUCCESS!', response.status, response.text);
    }, function (error) {
      console.log('FAILED...', error);
    });
}

const ContactForm = () => {
  return (
    <StyledDiv>
      <Formik
        initialValues={{ firstName: "", lastName: "", email: "", phone: "", message: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          sendEmail(values)
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Form.Row>
              <Form.Group as={Col} controlId="formName">
                <Form.Label >First Name :</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="Enter First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  isInvalid={errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formName">
                <Form.Label className="text-left" >Last Name :</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="Enter Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  isInvalid={errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="formEmail">
              <Form.Label >Email :</Form.Label>
              <Form.Control
                type="text"
                name="email"
                placeholder="Enter Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                isInvalid={errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formPhone">
              <Form.Label >Phone :</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Enter Phone Number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                isInvalid={errors.phone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label >Message :</Form.Label>
              <Form.Control
                type="textarea"
                name="message"
                placeholder="Enter Message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                isInvalid={errors.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors.message}
              </Form.Control.Feedback>
            </Form.Group>
            <StyledButton className="mt-3 text-white btn-dark" type="submit" disabled={isSubmitting}>
              Get In Touch
            </StyledButton>
          </Form>
        )}
      </Formik>
    </StyledDiv>
  );
}

export default ContactForm;
