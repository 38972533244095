import React from "react";
import { Button, Container } from "react-bootstrap";
import ContactForm from "../components/ContactForm";
import styled from "styled-components";
import Hero from "../components/Hero";
import heroImage from "./../images/hero-contact-image.jpg";

const InfoEmphasis = styled.span`
  color:#1a468d;
`;

const InfoLink = styled.a`
  color:#1a468d;
  text-decoration: underline;
`;

const StyledMain = styled.main`
p {
  font-size: 16px;
  text-align: justify;
  em{
  font-size: 12px;
  }
}

h2{
  font-size: 34px;
  color:#1a468d;
  margin-bottom: 0px;
}

h5{
  font-size: 22px;
  text-align: justify;
  color:#1a468d;
  margin-bottom: 0px;
}
`;

const Contact = () => {
  const heroContent = [
    <>
      <h1 className="my-5 display-4">Get In Touch</h1>
      <h1>We'd love to hear from you</h1>
    </>,
  ];

  return (
    <>
      <Hero image={heroImage} content={heroContent} />
      <StyledMain className="py-5">
        <Container>
          <div>
            <h5>Contact Information</h5>
            <hr className="mb-3 mt-1"></hr>
            <p><InfoEmphasis>Email:</InfoEmphasis> <InfoLink href="mailto:beardsleyoffice@gmail.com">beardsleyoffice@gmail.com</InfoLink></p>
            <p><InfoEmphasis>Phone:</InfoEmphasis> 704-395-3081</p>
            <p><InfoEmphasis>Mailing Address:</InfoEmphasis> 2400 Queen City Drive, Charlotte, NC 28208</p>
          </div>

          <div className="my-5">
            <h5>Contact Us Today</h5>
            <hr align="left" className="mb-3 mt-1"></hr>
            <p>
              <em>
                Fill in all required fields.
              </em>
            </p>
            <br />
            <ContactForm />
          </div>

          <p className="mb-0 mt-5">
            We are incredibly responsive to your requests and value your
            questions.
          </p>
          <p>
            We will respond to you within 2 business days.
          </p>
        </Container>
      </StyledMain>
    </>
  );
};

export default Contact;
