import React from "react";
import styled from "styled-components";
import Hero from "../components/Hero";
import heroImage from "./../images/printer_service.jpg";
import lanierLogo from "./../images/lanier-logo.png";
import ricohLogo from "./../images/ricoh-logo.png";
import savinLogo from "./../images/savin-logo.png";
import copierFull from "./../images/copier-full.png";

const StyledDiv = styled.div`
  text-align: left;
  font-size: 20px;
  .outerwrap {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 0px 50px 0px;
  }
  .textwrap{
    display:flex;
    align-items:center;
    img{
      margin-left:50px;
    }
  }
  .logoWrap {
    width: 100%;
    padding: 50px 0px 50px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    img {
      width: 15%;
    }
  }
`;

function Copiers() {
  const heroContent = [
    <>
      <h1 className="my-5 display-1">Refurbished Copiers</h1>
      <h5>
        Full performance at a fraction of the cost. Brands such as Ricoh, Savin
        and Lanier
      </h5>
    </>,
  ];

  return (
    <StyledDiv>
      <Hero image={heroImage} content={heroContent} />
      <div className="outerwrap h-100">
        <div className="textwrap h-100">
          <div className="h-100 d-flex my-auto mx-auto flex-column">
            <p>
              These innovative units deliver high quality full color performance
              to streamline your unique workflow demands. The advanced
              capabilities of one of our Ricoh, Savin or Lanier refurbished
              copiers helps simplify even the more complex printing, copying,
              scanning and distribution tasks. The units offer security features
              and impressive finishing capabilities as well as speed and
              reliability. The units we offer currently are able to print at 45
              copies per minute to 55 copies per minute.
            </p>
            <p>
              Our inventory is frequently changing and updating to be closer to
              the more advanced fully priced models. Enjoy full compatibility
              throughout the office with a host of network capabilities
              engineered specifically to enhance efficiency. Enjoy the ability
              to scan, share and store documents quickly and easily with
              Scan-to-Email/Folder/HDD. Our copiers also offer a High
              Compression PDF feature, which allows you to compress file sizes
              for faster, more reliable distribution. We will work with your IT
              staff to help with full integration of your new refurbished unit
              into your workplace environment.
            </p>
          </div>
          <img src={copierFull} />
        </div>
        <div className="logoWrap">
          <img src={lanierLogo} />
          <img src={ricohLogo} />
          <img src={savinLogo} />
        </div>
      </div>
    </StyledDiv>
  );
}

export default Copiers;
