import React from "react";
import styled from "styled-components";
import Hero from "../components/Hero";
import heroImage from "./../images/charlotte.jpg";

import savinlogo from "./../images/savin-logo.png";
import lanierlogo from "./../images/lanier-logo.png";
import ricohlogo from "./../images/ricoh-logo.png";

import { useHistory } from "react-router-dom";
import{ init } from 'emailjs-com';
init("user_IkE5mJqkZSDB8PviljJcD");

const StyledDiv = styled.div`
  flex-grow: 1;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  p {
    font-size: 20px;
  }
`;

const Button = styled.button`
  background-color: transparent;
  color:maroon;
  border: 1px solid maroon;
  font-size: 20px;
  padding: 10px;
  border-radius: 5px;
  margin: 3rem;
  align-self:center;
  cursor: pointer;
  &:hover{
    border: 1px solid white;
    color: white;
    background-color: maroon;
  }
`;

const LogoHolder = styled.div`
  /* background-color:white; */
  padding: 50px 0px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  img {
      width: 15%;
    }
`;

function Home() {
  const heroContent = [
    <>
      <h1 className="my-5 display-1">Beardsley Office Solutions, LLC.</h1>
      <h5>Call today to schedule: 704-395-3081</h5>
      <h5>Service starting at $85</h5>
    </>,
  ];

  const history = useHistory();

  function handleClick() {
    history.push("/contact");
    window.scrollTo(0, 0);
  }  

  return (
    <StyledDiv>
      <Hero image={heroImage} content={heroContent} />
      <p className="py-5 mx-auto w-75">
        Whether you're looking for copier service, repair, service contracts, or need to purchase used or refurbished copier, you have found the right place. We offer the best quality in our services and products at the most affordable prices. None of our competitors can offer the level of service and quality we provide. That is why we have built our business on customer referrals. With over 30 years of expierence, we make sure the job gets done right.
      </p>

      <LogoHolder>
        <img src={savinlogo} />
        <img src={ricohlogo} />
        <img src={lanierlogo} />
      </LogoHolder>

      <p className="py-5 mx-auto w-75">
        We specialize in the most reliable brands such as Savin, Ricoh, and Lanier.  All of our technicians are factory trained and certified.

        When it comes to copier sales and service repair, we provide all Charlotte and surrounding areas the most reliable copier and printer services in the industry. Not only do we provide excellent quality service, but also we offer the most competitive prices.
      </p>


      <Button onClick={handleClick}>CONTACT US TODAY</Button>

    </StyledDiv>
  );
}

export default Home;
