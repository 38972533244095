import React from "react";
import styled from "styled-components";

const Item = styled.li`
border: 0px;
background-color:transparent;
`;

const StyledLink = styled.a`
font-size:14px;
`;

function FooterLink({ text, link }) {
  return (
    <Item className="text-center ml-5 mr-5 list-group-item">
      {link ? <StyledLink href={link}> {text} </StyledLink> : <>{text}</>}
    </Item>
  );
}

export default FooterLink;