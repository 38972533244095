import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "./../images/logo.png"
import React, { useEffect } from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";

const HeaderDiv = styled.div`
.navbar{
  transition: all .2s ease;
  padding-top: 20px;
  padding-bottom: 20px;
}

.navbar-toggler{
  background-color: rgba(255,255,255,.4);
  border-color: rgba(255,255,255,.4);
}

.scrolled{
  background-color:rgba(0,0,0,.6);
  padding-top: 10px;
  padding-bottom: 10px;
}

a::first-letter {
  font-size: 125%;
}
`;

function Header() {
  const [scrolled, setScrolled] = React.useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  let x = ['navbar'];
  if (scrolled) {
    x.push('scrolled');
  }

  return (
    <HeaderDiv>
      <Navbar fixed="top" className={x.join(" ")} expand="lg">
        <Container fluid className="w-75">
          <Navbar.Brand href="/" className="text-light">
            <img
              alt=""
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
          Beardsley Office Solutions
        </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Link className="text-light ml-auto px-5" to='/copiers'>COPIERS</Link>
              <Link className="text-light ml-auto px-5" to='/about'>ABOUT US</Link>
              <Link className="text-light ml-auto px-5" to='/services'>SERVICES</Link>
              <Link className="text-light ml-auto px-5" to='/contact'>CONTACT US</Link>

              {/* <Nav.Link className="text-light ml-auto px-5" href="/copiers">COPIERS</Nav.Link> */}
              {/* <Nav.Link className="text-light ml-auto px-5" href="/about">ABOUT US</Nav.Link>
              <Nav.Link className="text-light ml-auto px-5" href="/services">SERVICES</Nav.Link>
              <Nav.Link className="text-light ml-auto px-5" href="/contact">CONTACT US</Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </HeaderDiv>
  );
}

export default Header;