import { BrowserRouter as Router, Switch, Route, HashRouter } from "react-router-dom";
import Home from './views/Home';
import Header from './components/Header';
import Footer from './components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Contact from './views/Contact';
import About from "./views/About";
import Copiers from "./views/Copiers";
import Services from "./views/Services";


function App() {
  return (
    <>
      <HashRouter>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/about" exact component={About} />
          <Route path="/copiers" exact component={Copiers} />
          <Route path="/services" exact component={Services} />
        </Switch>
      </HashRouter>
      <Footer />
    </>
  );
}

export default App;