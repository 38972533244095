import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Hero from "../components/Hero";
import heroImage from "./../images/charlotte.jpg";
import aboutImage from "./../images/about-us-image.jpg";

const StyledDiv = styled.div`
  text-align: left;
  font-size: 20px;

  .outerWrap {
    display: flex;
    width: 75%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding: 100px;

    img {
      width: 50%;
      margin-left: 50px;
    }

    @media (max-width: 1600px) {
      flex-direction: column;
      img {
        width: 100%;
        margin: 0;
        margin-top:50px;
      }
    }
  }
  .innerWrap {
  }
`;

function About() {
  const heroContent = [
    <>
      <h1 className="my-5 display-1">About Us</h1>
      <h5>Over 30 years of expierience. Established in 2002</h5>
    </>,
  ];

  return (
    <StyledDiv>
      <Hero image={heroImage} content={heroContent} />
      <div className="outerWrap">
        <div className="d-flex-column justify-content-center h-100">
          <p>
            We understand the small business well and the need to save money
            where you can. We are also a small business and can personally
            relate to the needs of others like us. Our staff has over 30 years
            of experience in the copier business and our business has been
            operating since 2002.
          </p>
          <p>
            We have a wide range of business customers from very small offices
            to medium and large sized businesses. Some of our customers have
            only one of our machines and others have several units in different
            locations within their companies or branch offices. Here at
            Beardsley Office Solutions we have everything the small to large
            sized business needs to get up and running with a full sized
            Multifunctional copier that is ready for the fast-paced workplace of
            your office. We refurbish used or off-lease units to work like the
            newer models and guarantee them as well.
          </p>
        </div>
        <img src={aboutImage} />
      </div>
    </StyledDiv>
  );
}

export default About;
