import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import styled from "styled-components";

const HeroImage = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, 0.5)), url(${props => props.imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 40vh;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const HeroContent = styled.div`
  text-align: center;
  color: white;
  height: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  h1, h5{
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
  }
`;

function Hero({ image, content }) {
  return (
    <HeroImage imageUrl={image}>
      <HeroContent>
        {content}
      </HeroContent>
    </HeroImage>
  );
}

export default Hero;