import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import FooterLink from "./FooterLink";
import bbbLogo from "./../../images/bbb-logo.png";
import sbLogo from "./../../images/small-business-logo.png";

const CopyWriteNotice = styled.p`
  text-align: center;
  font-size: 10px;
  color: maroon;
  width:100%;
  flex: 1 1 0;
`;

const ImgDiv = styled.div`
flex:1 1 0;
`;

const StyledFooter = styled.footer`
  background-color: #efefef;
  border-top: 2px;
  border-color: maroon;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    flex: 1 1 0;
  }
`;

const FooterContact = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  font-size: 12px;
  border-bottom: 1px solid maroon;
  p {
    flex: 1 1 0;
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <hr />
      <FooterContact className="py-4">
        <p>2400 Queen City Drive, Charlotte, NC 28208</p>
        <p className="middle">704-395-3081</p>
        <p>beardsleyoffice@gmail.com</p>
      </FooterContact>

      <div className="d-flex w-75 py-4 justify-content-md-around align-items-center">
        {/* <ImgDiv>
          <img width="100" src={bbbLogo} />
        </ImgDiv> */}
        <CopyWriteNotice>
          Beardsley Office Solutions, LLC. &copy;
        </CopyWriteNotice>
      </div>
    </StyledFooter>
  );
}

export default Footer;
