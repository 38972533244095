import React from "react";
import styled from "styled-components";
import Hero from "../components/Hero";
import heroImage from "./../images/service-hero.jpg";
import serviceImage from "./../images/service-image.png";

const TextDiv = styled.div`
  padding-bottom: 100px;
  text-align: left;
  width: 100%;

  display: flex;
  flex-direction: column;
  h5 {
    color: maroon;
  }
  p {
    font-size: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 75%;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  align-items: center;

  img {
    margin-left: 50px;
  }
`;

const TopText = styled.div`
  padding-top: 50px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  h5 {
    color: maroon;
  }
  p {
    font-size: 20px;
  }
`;

function Services() {
  const heroContent = [
    <>
      <h1 className="my-5 display-1">Copier Repair Services</h1>
      <h5>Call today to schedule: 704-395-3081</h5>
      <h5>Service starting at $85</h5>
    </>,
  ];

  return (
    <>
      <Hero image={heroImage} content={heroContent} />
      <TopText className="w-75 mx-auto">
        <h5>Service Contracts/Agreements</h5>
        <p>
          We offer yearly and monthly service contracts that include all parts,
          labor and supplies at a cost per copy basis.
        </p>
        <p> Rental units are available upon request as well.</p>
      </TopText>
      <Wrapper>
        <TextDiv>
          <h5>Service Calls</h5>
          <p>We repair Ricoh, Savin or Lanier copiers and printers.</p>
          <p>
            We offer repairs at $85 for black and white units and $125 for color
            units. Parts would be extra, but we will always be upfront with the
            cost before any repairs are done.
          </p>
          <h5>On Site Service and Covid 19 Safety</h5>
          <p>
            We provide on-site service and follow the covid safety protocols by
            masking up, remaining socially distanced and our employees
            frequently wash their hands or use hand sanitizers. We value the
            health and well being of our customers and employees. After a
            machine is serviced it will be thoroughly cleaned with a
            disinfectant on the highly touched areas of use.
          </p>
        </TextDiv>
        <img src={serviceImage} />
      </Wrapper>
    </>
  );
}

export default Services;
